import React from 'react'
import { View, Theme, Text } from '@/app'
import { Page, MapCard } from '@/shared'
import { variantProvider } from '@/app/theme'
import licenses from '@/app/license.json'

const Exemples: React.FC = () => {

  return (
    <Page variants={['column']}>
      <MapCard />

    </Page>
  )
}

const styles = variantProvider.createComponentStyle({
  wrapper: {
    minHeight: '100vh',
    ...Theme.presets.center,
    ...Theme.presets.flex,
    flexDirection: 'column',
    ...Theme.spacing.marginVertical(6),
  },
  content: {
    display: 'flex',
    width: 'auto',
    flexDirection: 'column',
  },
  itemWrapper: {
    paddingTop: Theme.spacing.value(2),
    marginBottom: Theme.spacing.value(2),
  },
}, true)

export default Exemples
